import React from "react";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

import Logo from "../assets/images/Digilicious-01.png";

const Navbar = () => {

    const closeNav = () => {
        document.getElementById("navi-toggle").checked = false;
    }

    return (
        <nav className="navbar">
            <Link to="/">
                <img src={ Logo } className="navbar__img" alt="logo"/>
            </Link>
            <div className="navbar-desktop">
                <ul className="navbar-desktop__items">
                    <Scroll to="home" smooth={ true }>
                        <li className="navbar-desktop__item">HOME</li>
                    </Scroll>
                    <Scroll to="about" smooth={ true }>
                        <li className="navbar-desktop__item">ABOUT</li>
                    </Scroll>
                    <Scroll to="services" smooth={ true }>
                        <li className="navbar-desktop__item">SERVICES</li>
                    </Scroll>
                    <Scroll to="clients" smooth={ true }>
                        <li className="navbar-desktop__item">CLIENTS</li>
                    </Scroll>
                    <Link to="/careers" style={ { textDecoration: "none" } }>
                        <li className="navbar-desktop__item">JOIN US</li>
                    </Link>
                    <Scroll to="footer" smooth={ true }>
                        <li className="navbar-desktop__item">LET'S CONNECT</li>
                    </Scroll>
                </ul>
            </div>
            <div className="navbar-mobile">
                <input type="checkbox" className="navbar-mobile__checkbox" id="navi-toggle"/>
                <label htmlFor="navi-toggle" className="navbar-mobile__button">
                    <span className="navbar-mobile__icon"/>
                </label>
                <div className="navbar-mobile__background"/>
                <div className="navbar-mobile__nav">
                    <ul className="navbar-mobile__list">
                        <li className="navbar-mobile__item">
                            <Scroll
                                to="home"
                                smooth={ true }
                                className="navbar-mobile__link"
                                onClick={ closeNav }
                            >
                                Home
                            </Scroll>
                        </li>
                        <li className="navbar-mobile__item">
                            <Scroll
                                to="about"
                                smooth={ true }
                                className="navbar-mobile__link"
                                onClick={ closeNav }
                            >
                                About
                            </Scroll>
                        </li>
                        <li className="navbar-mobile__item">
                            <Scroll
                                to="services"
                                smooth={ true }
                                className="navbar-mobile__link"
                                onClick={ closeNav }
                            >
                                Services
                            </Scroll>
                        </li>
                        <li className="navbar-mobile__item">
                            <Scroll
                                to="clients"
                                smooth={ true }
                                className="navbar-mobile__link"
                                onClick={ closeNav }
                            >
                                Clients
                            </Scroll>
                        </li>
                        <li className="navbar-mobile__item">
                            <Link
                                to="/careers" smooth={ true }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="navbar-mobile__link">
                                Careers
                            </Link>
                        </li>
                        <li className="navbar-mobile__item">
                            <Scroll
                                to="footer"
                                smooth={ true }
                                className="navbar-mobile__link"
                                onClick={ closeNav }
                            >
                                Contact
                            </Scroll>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
