import React from "react";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Reveal';

import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";
import Twitter from "../assets/images/twitter.svg";
import Linkedin from "../assets/images/linkedin.svg";

const Footer = () => {


  return (
    <footer className="footer" id="footer">
      <Fade right>
        <div className="contact">
          <div className="contact__heading">
            <h2 className="heading-primary heading-primary--white">Get connected</h2>
            <h4 className="heading-sub heading-sub--white">&mdash;Over a cup of hot or cold brew, maybe?</h4>
            <p className="paragraph paragraph--white u-margin-top-medium">
              Have an idea? Want to brainstorm?
              Or simply want to learn more about our services work?
            </p>
            <Link to="/brand-form" style={ { textDecoration: "none" } }>
              <button className="btn btn-brand">I'm an Influencer</button>
            </Link>
            <Link to="/influencer-form" style={ { textDecoration: "none" } }>
              <button className="btn btn-influencer">I'm an Brand</button>
            </Link>
          </div>
          <div className="contact__details">
            <p className="paragraph paragraph--white u-margin-top-small">
              CALL: <b>+91-9560253475</b>
            </p>
            <p className="paragraph paragraph--white u-margin-top-small">
              EMAIL: <b>hola@digilicious.in</b>
            </p>
            <p className="paragraph paragraph--white u-margin-top-small">
              ADDRESS: <b>DIGILICIOUS<br/>Emaar Digital Greens, 11th Floor, Tower B,<br/>Golf Course
              Extension Road, Sector 61,<br/>Gurugram, Haryana - 122102</b>
            </p>
            <Link to="/privacy" style={ { textDecoration: 'none' } }>
              <p className="rights u-margin-top-medium">
                Privacy Policy
              </p>
            </Link>
          </div>
          <div className="contact__social u-margin-top-medium">
            <div className="contact__social--icons">
              <a href="https://www.facebook.com/DigiliciousIN/" target="_blank" rel="noopener noreferrer">
                <img src={ Facebook } alt="Facebook" className="contact__social-icon"/>
              </a>
              <a href="https://www.instagram.com/digilicious_india/" target="_blank" rel="noopener noreferrer">
                <img src={ Instagram } alt="Instagram" className="contact__social-icon"/>
              </a>
              <a href="https://twitter.com/digilicious_in" target="_blank" rel="noopener noreferrer">
                <img src={ Twitter } alt="Twitter" className="contact__social-icon"/>
              </a>
              <a href="https://www.linkedin.com/company/digiliciousindia/" target="_blank" rel="noopener noreferrer">
                <img src={ Linkedin } alt="Linkedin" className="contact__social-icon"/>
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </footer>
  );
}

export default Footer;
