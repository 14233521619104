import React from "react";
import Navbar from "./layout/Navbar";
import Header from "./layout/Header";
import About from "./components/About";
import Services from "./components/Services";
import Footer from "./layout/Footer";
import Clients from "./components/Clients";

const Home = () => {
    return (
        <div>
            <Navbar/>
            <Header/>
            <main>
                <About/>
                <Services/>
                <Clients/>
                <Footer/>
            </main>
        </div>
    )
}

export default Home;
