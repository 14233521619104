import React from "react";
import Navbar from "../layout/Navbar";

const Careers = () => {
    return (
        <div>
            <Navbar/>
            <div className="careers">
                <div className="careers__background">
                    <h2 className="careers__heading">
                        Come work with us
                    </h2>
                </div>
                <div className="careers__content">
                    <h1>Currently, we don’t have any relevant job openings.</h1>
                    <h2>if you wish to work with us send your resume at &nbsp;
                        <a href="mailto:hr@digilicios.in" className="careers-link">
                            hr@digilicious.in
                        </a>
                    </h2>
                    <h1>You never know what might turn up!</h1>
                </div>
            </div>
        </div>
    )
}

export default Careers;
