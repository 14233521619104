import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Home";
import InfluencerForm from "./components/InfluencerForm";
import BrandForm from "./components/BrandForm";
import Careers from "./components/Careers";
import PrivacyPolicy from "./components/PrivacyPolicy";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={ Home }/>
        <Route path="/influencer-form" exact component={ InfluencerForm }/>
        <Route path="/brand-form" exact component={ BrandForm }/>
        <Route path="/careers" exact component={ Careers }/>
        <Route path="/privacy" exact component={ PrivacyPolicy }/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;
