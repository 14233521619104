import React, { useState } from "react";

import Logo from "../assets/images/Digilicious-01.png";
import { Link, Redirect } from "react-router-dom";
import { brandForm } from "../services/ApiService";

const BrandForm = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        phone_number: '',
        instagram_link: '',
        youtube_link: '',
        facebook_link: '',
        success: false,
        getRedirect: false
    })

    const {
        name,
        email,
        phone_number,
        instagram_link,
        youtube_link,
        facebook_link,
        success,
        getRedirect
    } = values;

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value })
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, success: true });
        brandForm({ name, email, phone_number, instagram_link, youtube_link, facebook_link })
            .then(data => {
                if (data === "sent") {
                    setValues({
                        ...values,
                        name: '',
                        email: '',
                        phone_number: '',
                        instagram_link: '',
                        youtube_link: '',
                        facebook_link: '',
                        success: true
                    })
                }
            }).catch(console.log('Error in submitting the form'));
    }

    const didRedirect = () => {
        if (success) {
            setTimeout(() => {
                setValues({ ...values, getRedirect: true })
            }, 2000)
        }
    }

    return (
        <div className="content-brand">
            <div className="content-brand__left-side">
                <Link to="/">
                    <img src={ Logo } alt="Logo" className="content-brand__left-side__logo"/>
                </Link>
                <div className="content-brand__left-side__text">
                    <h1 className="content-brand__left-side__text-heading">
                        Hola Influencers
                    </h1>
                    <p className="content-brand__left-side__text-paragraph">
                        Join our network of influencers and increase your business
                    </p>
                </div>
            </div>
            <div className="content-brand__right-side">
                <div className="content-brand__right-side__about">
                    <p className="content-brand__right-side__about-text">
                        Influencers? Join Here
                    </p>
                </div>
                <div className="influencer-form">
                    { didRedirect() }
                    <form className="form" action="#">
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Name"
                                id="name"
                                required
                                onChange={ handleChange('name') }
                            />
                            <label htmlFor="name" className="form__label">Name</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="email"
                                className="form__input"
                                placeholder="Email"
                                id="email"
                                onChange={ handleChange('email') }
                                required
                            />
                            <label htmlFor="email" className="form__label">Email</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Phone Number"
                                id="phoneNumber"
                                onChange={ handleChange('phone_number') }
                                required
                            />
                            <label htmlFor="phoneNumber" className="form__label">Phone Number</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Instagram Link"
                                id="instagram"
                                onChange={ handleChange('instagram_link') }
                                required
                            />
                            <label htmlFor="instagram" className="form__label">Instagram Link</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Facebook Link"
                                id="facebook"
                                onChange={ handleChange('facebook_link') }
                                required/>
                            <label htmlFor="facebook" className="form__label">Facebook Link</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Youtube Link"
                                id="youtube"
                                onChange={ handleChange('youtube_link') }
                                required/>
                            <label htmlFor="Youtube" className="form__label">Youtube Link</label>
                        </div>
                        <div className="form__group">
                            <button className="btn btn-submit" onClick={ onSubmit }>
                                Send &rarr;
                            </button>
                        </div>
                    </form>
                </div>
                { getRedirect ? <Redirect to="/"/> : null }
            </div>
        </div>
    )
}

export default BrandForm;
