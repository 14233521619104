import React from "react";
import Fade from 'react-reveal/Reveal';

import Adda from "../assets/images/Adda52_Logo.png"
import Beardo from "../assets/images/beardo.png"
import BullTv from "../assets/images/BulBulTv.png"
import Caprese from "../assets/images/clogo.png"
import DDF from "../assets/images/DDF.png"
import DishTv from "../assets/images/DishTV_Logo.png"
import Droom from "../assets/images/droom.png"
import Junglee from "../assets/images/Junglee_Games_logo.png"
import Oppo from "../assets/images/oppo.png";
import OYO from "../assets/images/OYO.png";
import Panasonic from "../assets/images/panasonic.png"
import Realme from "../assets/images/Realme.png"
import Rodeo from "../assets/images/Roaded.png"
import RoyalBlue from "../assets/images/RoyalBlueEvent.png";
import Schneider from "../assets/images/Schneider_Logo.png"
import Stage from "../assets/images/stage_logo.png";
import Indus from "../assets/images/TIV-logo.png"
import Vip from "../assets/images/VIP-logo.png"
import Watcho from "../assets/images/watcho.png";

const Clients = () => {

    return (
        <section className="section-clients" id="clients">
            <Fade right>
                <div className="row">
                    <div className="col-1-of-2">
                        <h2 className="heading-primary heading-primary">Clients</h2>
                        <h4 className="heading-sub heading-sub">&mdash;&mdash;We grew together!</h4>
                    </div>
                </div>
                <div className="ticker-wrap">
                    <div className="ticker">
                        <div className="ticker__item">
                            <img src={ Adda } alt="Adda52"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Beardo } alt="Beardo" className="beardo"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ BullTv } alt="BullTv" className="bull"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Caprese } alt="Caprese"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ DDF } alt="Delhi Duty Free"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ DishTv } alt="DishTv"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Droom } alt="Droom"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Junglee } alt="Junglee"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Oppo } alt="Oppo"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ OYO } alt="OYO"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Panasonic } alt="Panasonic"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Realme } alt="Realme"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Rodeo } alt="Rodeo"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ RoyalBlue } alt="Royal Blue"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Schneider } alt="Schneider"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Stage } alt="Stage"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Indus } alt="Indus Valley"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Vip } alt="Vip"/>
                        </div>
                        <div className="ticker__item">
                            <img src={ Watcho } alt="Watcho"/>
                        </div>
                    </div>
                </div>
            </Fade>
        </section>
    )
}

export default Clients;
