import React from "react";
import Fade from "react-reveal/Reveal";

import AboutImg from "../assets/images/about-img.png"

const About = () => {

    return (
        <section className="section-about load-hidden" id="about">
            <Fade right>
                <div className="row">
                    <div className="col-1-of-2">
                        <h2 className="heading-primary heading-primary--white">About Us</h2>
                        <h4 className="heading-sub heading-sub--white">&mdash;&mdash;Who we are</h4>
                        <h3 className="heading-secondary heading-secondary--white u-margin-top-medium">
                            Engraving brands in the sub-conscious.
                        </h3>
                        <p className="paragraph paragraph--white u-margin-top-medium">
                            We are a Creatively Led, Strategically Driven, Technology Friendly Full Service Digital
                            Marketing Agency. We Dive Deeper to understand your Goals, Challenges, and Audiences to help
                            carve Effective Roadmaps and take your marketing initiatives a Notch Higher.<br/>


                            We offer Strategic Consulting, Brand Building Ideas, Campaign Planning & Execution, Social
                            Media Management, Content Strategy, PR, Influencer & Celebrity Marketing, Blog Marketing,
                            Effective Designing, Website & Landing Page Creation & Maintenance, Media Planning & Buying,
                            SEO, Online Reputation Management, Brand & Corporate Films, Product Photography, Live Event
                            Coverage, Customer Testimonial shoots, & much more! That’s a long list, ain’t it? <br/>

                            You name it, we have it! <br/>


                            We’re an agency that always places business impact above everything else. Real business
                            impact that makes a difference to Brand Awareness, Sales and Visibility, Every single time.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <img src={ AboutImg } className="about-img u-margin-top-big" alt="about-img"/>
                    </div>
                </div>
            </Fade>
        </section>
    )
}

export default About;
