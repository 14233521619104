const API = "https://digilicious-backend.herokuapp.com";

export const influencerForm = influencer => {
    return fetch(`${ API }/influencer/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(influencer)
    }).then(res => {
        return res.json()
    }).catch(err => console.log(err))
};

export const brandForm = brand => {
    return fetch(`${ API }/brand/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(brand)
    }).then(res => {
        return res.json()
    }).catch(err => console.log(err))
};
