import React from "react";
import Fade from "react-reveal/Reveal";

import LandingImg from "../assets/images/landing-img.png"

const Header = () => {

    return (
        <header className="header" id="home">
            <Fade left>
                <div className="row">
                    <div className="col-1-of-2">
                        <div className="header__img-container">
                            <img className="header__img" src={ LandingImg } alt="landing-img"/>
                        </div>
                    </div>
                    <div className="col-1-of-2">
                        <div className="header__text-box">
                            <h1 className="header__text">
                                We
                                <div className="animate-words">
                                    <span>Create</span>
                                    <span>Build</span>
                                </div>
                                <pre>Brands</pre>
                            </h1>
                        </div>
                    </div>
                </div>
            </Fade>
        </header>
    )
}

export default Header;
