import React, { useState } from "react";

import Logo from "../assets/images/Digilicious-01.png";
import { Link, Redirect } from "react-router-dom";
import { influencerForm } from "../services/ApiService";

const InfluencerForm = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        phone_number: '',
        message: '',
        success: false,
        getRedirect: false
    })

    const { name, email, phone_number, message, success, getRedirect } = values;

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value })
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, success: true });
        influencerForm({ name, email, phone_number, message })
            .then(data => {
                if (data === "sent") {
                    setValues({
                        ...values,
                        name: '',
                        email: '',
                        phone_number: '',
                        message: '',
                        success: true
                    })
                }
            }).catch(console.log('Error in submitting the form'));
    };

    const didRedirect = () => {
        if (success) {
            setTimeout(() => {
                setValues({ ...values, getRedirect: true })
            }, 2000)
        }
    }

    return (
        <div className="content-influencer">
            <div className="content-influencer__left-side">
                <Link to="/">
                    <img src={ Logo } alt="Logo" className="content-influencer__left-side__logo"/>
                </Link>
                <div className="content-influencer__left-side__text">
                    <h1 className="content-influencer__left-side__text-heading">
                        Are you a brand looking to connect ?
                    </h1>
                    <p className="content-influencer__left-side__text-paragraph">
                        Please fill up this form and we'll get back to you within 24 hours
                    </p>
                </div>
            </div>
            <div className="content-influencer__right-side">
                <div className="content-influencer__right-side__about">
                    <p className="content-influencer__right-side__about-text">
                        Brand? Join Here
                    </p>
                </div>
                <div className="influencer-form">
                    { didRedirect() }
                    <form className="form" action="#">
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Name"
                                id="name"
                                onChange={ handleChange('name') }
                                required
                            />
                            <label htmlFor="name" className="form__label">Name</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="email"
                                className="form__input"
                                placeholder="Email"
                                id="email"
                                onChange={ handleChange('email') }
                                required
                            />
                            <label htmlFor="email" className="form__label">Email</label>
                        </div>
                        <div className="form__group">
                            <input
                                type="text"
                                className="form__input"
                                placeholder="Phone Number"
                                id="phoneNumber"
                                onChange={ handleChange('phone_number') }
                                required
                            />
                            <label htmlFor="phoneNumber" className="form__label">Phone Number</label>
                        </div>
                        <div className="form__group">
                            <textarea
                                className="form__input"
                                placeholder="Your Message"
                                id="message"
                                onChange={ handleChange('message') }
                                required/>
                            <label htmlFor="message" className="form__label">Your Message</label>
                        </div>
                        <div className="form__group">
                            <button className="btn btn-submit" onClick={ onSubmit }>
                                Send &rarr;
                            </button>
                        </div>
                    </form>
                </div>
                { getRedirect ? <Redirect to="/"/> : null }
            </div>
        </div>
    )
}

export default InfluencerForm;
