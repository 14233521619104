import React from "react";
import Fade from 'react-reveal/Reveal';

import ServiceImg from "../assets/images/service-img.png";
import InfluencerMarket from "../assets/images/IM_EA.png";
import ContentCreation from "../assets/images/CC.png";
import CelebManagement from "../assets/images/CM.png";
import VideoProduction from "../assets/images/VP.png";
import ContentMarketing from "../assets/images/CoM.png";
import EventAction from "../assets/images/EA.png";
import SEO from "../assets/images/SEO.png";
import WDB from "../assets/images/WDB.png";
import ORM from "../assets/images/ORM.png";
import MPB from "../assets/images/MPB.png";

const Services = () => {

    return (
        <section className="section-services" id="services">
            <Fade left>
                <div className="row">
                    <div className="col-1-of-2">
                        <h2 className="heading-primary">Services</h2>
                        <h4 className="heading-sub heading-sub--black">&mdash;&mdash;The Solutions We Provide</h4>
                        {/*<h3 className="heading-secondary u-margin-top-medium">*/}
                        {/*    We don’t market brand, we create brands.*/}
                        {/*</h3>*/}
                        <p className="paragraph u-margin-top-medium">
                            We don’t just provide services; we also ensure that there’s supreme quality in whatever we do.
                            We’ll be there to hold your hand every step of the way. We believe in building real &amp; long-lasting
                            bonds with our clients.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <img src={ ServiceImg } className="service-img" alt="about-img"/>
                    </div>
                </div>
                <div className="ticker-wrap">
                    <div className="ticker">
                        <div className="ticker__item">
                            <img src={ InfluencerMarket } alt="Adda52"/>
                            <h3 className="heading-tertiary">Influencer Marketing</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ ContentCreation } alt="Beardo" className="beardo"/>
                            <h3 className="heading-tertiary">Content Creation</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ CelebManagement } alt="BullTv" className="bull"/>
                            <h3 className="heading-tertiary">Celebrity Management</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ VideoProduction } alt="Caprese"/>
                            <h3 className="heading-tertiary">Video Production</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ ContentMarketing } alt="Delhi Duty Free"/>
                            <h3 className="heading-tertiary">Content Marketing</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ EventAction } alt="DishTv"/>
                            <h3 className="heading-tertiary">Events & Action</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ SEO } alt="Droom"/>
                            <h3 className="heading-tertiary">SEO</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ WDB } alt="Junglee"/>
                            <h3 className="heading-tertiary">Website Development & Management</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ ORM } alt="Oppo"/>
                            <h3 className="heading-tertiary">ORM</h3>
                        </div>
                        <div className="ticker__item">
                            <img src={ MPB } alt="OYO"/>
                            <h3 className="heading-tertiary">Media Planning and buying</h3>
                        </div>
                    </div>
                </div>
                {/*<div className="services u-margin-top-medium">*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ InfluencerMarket } alt="Influencer Market" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Influencer Marketing</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ ContentCreation } alt="Content Creation" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Content Creation</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card services__card--blue">*/}
                {/*        <img src={ CelebManagement } alt="Celebrity Management" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Celebrity Management</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ VideoProduction } alt="Video Production" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Video Production</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card services__card--blue">*/}
                {/*        <img src={ ContentMarketing } alt="Content Marketing" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Content Marketing</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ EventAction } alt="Events & Activation" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Events & Activation</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card services__card--blue">*/}
                {/*        <img src={ SEO } alt="SEO" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">SEO</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ MPB } alt="MPB" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Media Planning and buying</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card services__card--blue">*/}
                {/*        <img src={ ORM } alt="ORM" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">ORM</h3>*/}
                {/*    </div>*/}
                {/*    <div className="services__card">*/}
                {/*        <img src={ WDB } alt="WDB" className="services__card-icon"/>*/}
                {/*        <h3 className="heading-tertiary">Website Development & Branding</h3>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Fade>
        </section>
    )
}

export default Services;
